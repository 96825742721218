import {Zap, Shuffle, Activity} from 'react-feather';
import {Link} from 'gatsby';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import React from 'react';
import './function.css';

import Button from '../components/Button';
import CTA from '../components/CTA';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {
  return (
    <Layout spacingTop={false}>
      <SEO title="Fleet Traffic Shifting - Fleet" />
      <section className="relative px-6 lg:px-12">
        <div className="container py-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex items-center justify-center">
              <div>
                <p className="font-semibold text-gray-light leading-tight mb-3">
                  PRIVATE BETA
                </p>
                <div className="flex items-center">
                  <img
                    className="select-none w-12 h-12"
                    alt="Fleet Traffic Shifting"
                    src="/images/logo-fleet-ts.svg"
                  />
                  <h1 className="ml-3 text-3xl font-semibold leading-relaxed">
                    Fleet <span className="font-normal">Traffic Shifting</span>
                  </h1>
                </div>
                <p className="text-lg mt-6 mb-10 leading-normal text-gray">
                  Have automated traffic change control for your critical
                  services in canary deployments.
                </p>
                <Button
                  component="a"
                  className="sm:mr-4"
                  size="lg"
                  href="https://console.fleetfn.com"
                  onClick={() => {
                    trackCustomEvent({
                      category: 'Request Early Access [TS Page]',
                      action: 'Click',
                    });
                  }}
                >
                  Request Early Access
                </Button>
                <Button
                  component={Link}
                  className="sm:mt-0 mt-4"
                  size="lg"
                  variant="primary-light"
                  to="/contact"
                >
                  Contact Sales
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-center sm:mt-0 mt-12">
              <img
                className="select-none sm:max-w-md"
                alt="Fleet Traffic Shifting"
                src="/images/fleet-traffic-shifting.svg"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-16 lg:mt-48 mb-16 sm:mb-40 px-6">
        <div className="container">
          <div className="text-center">
            <h2 className="font-bold text-3xl sm:text-4xl mb-8">
              Shifting traffic with confidence
            </h2>
            <p className="font-medium text-gray text-xl max-w-3xl inline-block eading-relaxed">
              Fleet TS helps your team to deploy critical services to meet high
              demand, creating automated or manual traffic changes to test the
              new deployment with a part of your base.
            </p>
          </div>
          <div className="flex items-center justify-center mt-16">
            <img
              className="mt-16 select-none"
              alt="Various possibilities with rules"
              src="/images/fleet-ts-variants.svg"
            />
          </div>
        </div>
      </section>
      <section className="bg-white-gray mt-24 sm:mt-40 mb-24 lg:mb-24 px-6 sm:px-16 py-24">
        <div className="container relative">
          <h2 className="text-2xl font-semibold">Features</h2>
          <p className="text-lg leading-normal mt-6">
            Critical microservices and APIs that meet a large scale of user
            demand are critical and cannot fail due to bugs in the code. The
            main adoption of mitigation is canary deployments.
          </p>
          <ul className="mt-24 grid sm:grid-cols-3 grid-cols-1 sm:gap-32 gap-16">
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Zap size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">Automation rules</h3>
                <p className="text-base">
                  Automate your canary implantations based on traffic data to
                  decrease monitoring and mitigate errors from expensive
                  deployments.
                </p>
              </div>
            </li>
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Shuffle size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">
                  Manual traffic control
                </h3>
                <p className="text-base">
                  Perform manual traffic control as you get more data.
                </p>
              </div>
            </li>
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Activity size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">Monitoring</h3>
                <p className="text-base">
                  Monitor traffic to make the best decisions and adjust the
                  rules to your reality.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="mt-16 lg:mt-48 sm:mb-40 mb-16 px-6">
        <div className="container">
          <div className="text-center">
            <h2 className="font-bold text-4xl mb-8">
              Create faster solutions with functions
            </h2>
            <p className="font-medium text-gray text-xl max-w-3xl inline-block eading-relaxed">
              Use the Fleet to build your critical services that require
              resilience, high demand, low cost and high speed with our
              technology.
            </p>
          </div>
          <ul className="mt-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="JAMstack"
                  src="/images/JAMstack.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">JAMstack</h3>
                <p className="text-base text-gray">
                  Enrich the JAMstack ecosystem using functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Microservices"
                  src="/images/microservices.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Microservices</h3>
                <p className="text-base text-gray">
                  Build your service fleet on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Micro APIs"
                  src="/images/apis.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">APIs</h3>
                <p className="text-base text-gray">
                  Build your product's APIs on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Data Processing"
                  src="/images/tasks.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Tasks</h3>
                <p className="text-base text-gray">
                  Orchestrate background functions for data processing
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <CTA />
    </Layout>
  );
};
